body {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: #f8f9fa;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensures full-page height */
    box-sizing: border-box; /* Includes padding and border in height calculations */
}

.container {
    width: 100%;
    padding: 20px; /* Adds breathing room */
    box-sizing: border-box; /* Prevents padding from affecting width */
}

img {
    max-width: 300px; /* Prevents the logo from being too wide */
    margin-bottom: 20px; /* Adds spacing below the logo */
}
a {
    color: #007bff;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
form {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;    /* Center items horizontally */
    gap: 10px;              /* Add space between elements */
    width: 100%;            /* Allow full width of parent container */
    max-width: 400px;       /* Optional: Limit form width */
    margin: 20px auto;      /* Center form on the page */
}
form input,
form textarea,
form button {
    width: 100%;            /* Make inputs stretch to full width */
    max-width: 300px;       /* Optional: Limit input width */
    padding: 10px;          /* Add padding for better usability */
    border: 1px solid #ccc; /* Add border */
    border-radius: 5px;     /* Rounded corners */
    font-size: 16px;        /* Ensure consistent font size */
}

form button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

form button:hover {
    background-color: #0056b3;
}
form textarea {
    resize: none;
}
form button {
    background-color: #007bff;
    color: white;
    border: none;
}
.social-links {
    margin-top: 20px;
}
.social-links a {
    margin: 0 10px;
    font-size: 24px;
    color: #007bff;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}
.social-links a:hover {
    color: #0056b3;
}
.social-links .material-icons {
    font-size: 24px;
    margin-right: 5px;
}
